import WebComponent from "../webcomponent.js"

const template = (obj) => html`
  <style>
    #qrcode {
      display: flex;
      flex-direction: row;
      justify-content: center;
      cursor: pointer;
    }
  </style>
  <div id="qrcode"></div>
  <p>
    <ion-input id="qrcode-text" type="text">
      <ion-button id="btnCopyUrl" slot="end" fill="clear" aria-label="Copy to clipboard">
        <ion-icon slot="icon-only" name="clipboard-outline" aria-hidden="true"></ion-icon>
      </ion-button>
    </ion-input>
  </p>
`

customElements.define('app-qrcode', class extends WebComponent {
  init() {
  }

  render() {
    this.shadow.innerHTML = template(this)
    this.qrcodeDiv = this.shadow.querySelector("#qrcode")
    this.qrcodeDiv.addEventListener('click', () => {
      this.qrcodeDiv.children[0].toBlob(blob => {
        navigator.clipboard.write([new ClipboardItem(
          {'image/png': blob})
        ])
        app.toast("QR Code copied to clipboard")
      })
    })
    this.shadow.querySelector("#btnCopyUrl").addEventListener('click', () => {
      navigator.clipboard.writeText(this.url)
      app.toast("URL copied to clipboard")
    })
  }

  setUrl(url) {
    this.url = url
    this.qrcodeDiv.innerHTML = "";
    new QRCode(this.qrcodeDiv, this.url);
    this.shadow.querySelector("#qrcode-text").value = this.url
  }
});
